import React, { FC } from "react";

interface StatusCircleIconProps {
  color?: string;
}

const StatusCircleIcon: FC<StatusCircleIconProps> = ({ color = "#63CBA5" }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="12" height="12" rx="6" fill={color} />
    </svg>
  );
};

export default StatusCircleIcon;
