import { Segmented, Spin } from "antd";
import React, { FC, useRef, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import ArrowSquareRightIcon from "../../assets/icons/ArrowSquareRightIcon";
import CubeIcon from "../../assets/icons/CubeIcon";
import Element3Icon from "../../assets/icons/Element3Icon";
import StatusCircleIcon from "../../assets/icons/StatusCircleIcon";
import Block from "../Visual/Block/Block";
import Floor from "../Visual/Floor/Floor";
import Visual from "../Visual/Visual";
import styles from "./bodyContainer.module.scss";
import Entrance from "./Entrance";
import NumberContainer from "./NumberContainer";
import queryString from "query-string";

interface IProps {
  data: any[];
  loading: boolean;
  fetching: boolean;
  visible: boolean;
  setOpenDrawer: React.Dispatch<
    React.SetStateAction<{
      id: number;
      visible: boolean;
    }>
  >;
  flatLength: number;
  floorLength: number;
  flatId: number;
  floorMaxCount: number;
}

const BodyContainer: FC<IProps> = ({
  visible,
  setOpenDrawer,
  data,
  flatLength,
  floorLength,
  loading,
  fetching,
  flatId,
  floorMaxCount,
}) => {
  const navigate = useNavigate();
  const [position, setPosition] = useState({
    startY: 0,
    startX: 0,
    scrollLeft: 0,
    scrollTop: 0,
    isDown: false,
  });
  const bodyRef = useRef<any>(null);

  const bodyWheele = (e: React.WheelEvent<HTMLDivElement>) => {
    e.preventDefault();
    const { deltaY } = e;
    bodyRef.current.scrollLeft += deltaY;
  };

  const bodymouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (position.isDown) {
      e.preventDefault();
      const y = e.pageY - bodyRef.current.offsetTop;
      const walkY = y - position.startY;
      bodyRef.current.scrollTop = position.scrollTop - walkY;

      const x = e.pageX - bodyRef.current.offsetLeft;
      const walkX = x - position.startX;
      bodyRef.current.scrollLeft = position.scrollLeft - walkX;
    }
  };
  const bodymouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    setPosition((prev) => ({
      ...prev,
      isDown: false,
    }));
  };
  const bodymouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setPosition({
      isDown: true,
      startY: e.pageY - bodyRef.current.offsetTop,
      startX: e.pageX - bodyRef.current.offsetLeft,
      scrollTop: bodyRef.current.scrollTop,
      scrollLeft: bodyRef.current.scrollLeft,
    });
  };
  const bodymouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    setPosition((prev) => ({
      ...prev,
      isDown: false,
    }));
  };

  const simple = queryString.parse(window.location.search)?.simple;

  return (
    <div
      className={`${styles.bodyContainer} ${
        !simple && visible ? styles.bodyContainer_active : ""
      }`}
      style={
        simple
          ? {
              left: 24,
              marginTop: 24,
              width: visible ? "" : "calc(100% - 48px)",
            }
          : {}
      }
    >
      {!simple && (
        <div className={styles.bodyContainer_header}>
          <div className={styles.bodyContainer_header_flatCount}>
            Topilgan uylar soni <span>{flatLength}</span>
          </div>
          <Segmented
            className="bodyContainer_header_segmented"
            options={[
              {
                icon: <Element3Icon />,
                value: "/",
                label: "Shaxmatka",
              },
              {
                icon: <CubeIcon />,
                value: "/visual",
                label: "Vizual ko’rinish",
              },
            ]}
            onChange={(e: any) => {
              navigate(e + window.location.search);
            }}
          />
          <div className={styles.bodyContainer_header_flatStatus}>
            <p>
              <StatusCircleIcon color="#63CBA5" /> Bo’sh
            </p>
            <p>
              <StatusCircleIcon color="#FF6565" /> Sotilgan
            </p>
            <p>
              <StatusCircleIcon color="#FFB400" /> Band qilingan
            </p>
            <p>
              <StatusCircleIcon color="#A6A6A6" /> Sotilmaydi
            </p>
          </div>
          {visible && (
            <span
              className={`BodyContainerArrowRight ${visible ? "" : "active"}`}
              onClick={() => setOpenDrawer({ id: 0, visible: !visible })}
            >
              <ArrowSquareRightIcon />
            </span>
          )}
        </div>
      )}
      <Routes>
        <Route
          path="/"
          element={
            <Spin spinning={loading || fetching} className="bodyContainer_body">
              <div
                className={`${styles.bodyContainer_body} `}
                ref={bodyRef}
                onWheel={bodyWheele}
                onMouseMove={bodymouseMove}
                onMouseUp={bodymouseUp}
                onMouseDown={bodymouseDown}
                onMouseLeave={bodymouseLeave}
              >
                <NumberContainer
                  floorMaxCount={floorMaxCount}
                  floorLength={floorLength}
                  style={{
                    marginRight: "-40px",
                  }}
                />
                {data &&
                  data.length > 0 &&
                  data?.map((item: any, index: number) => (
                    <Entrance
                      key={index}
                      data={item}
                      flatId={flatId}
                      setOpenDrawer={setOpenDrawer}
                    />
                  ))}
                <NumberContainer
                  floorMaxCount={floorMaxCount}
                  floorLength={floorLength}
                  style={{
                    right: 0,
                    width: 50,
                  }}
                />
              </div>
            </Spin>
          }
        />
        <Route path="/visual" element={<Visual />} />
        <Route path="/visual/:block" element={<Block />} />
        <Route
          path="/visual/:block/:floor"
          element={<Floor setOpenDrawer={setOpenDrawer} flatId={flatId} />}
        />
      </Routes>
    </div>
  );
};

export default BodyContainer;
