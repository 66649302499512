import { atom } from "recoil";

export const AllHouseFilter = atom({
  key: "AllHouseFilter",
  default: {
    rooms: [1, 2, 3, 4, 5],
    maxArea: 160,
    minArea: 0,
    maxPrice: 5000000000,
    minPrice: 1000000,
    maxPerPrice: 15000000,
    minPerPrice: 1000000,
    repaired: "null",
    empty: false,
    attached: false,
  },
});

export const AllHouseFilterDefault = atom({
  key: "AllHouseFilterDefault",
  default: {
    rooms: [1, 2, 3, 4, 5],
    maxArea: 160,
    minArea: 0,
    maxPrice: 5000000000,
    minPrice: 1000000,
    maxPerPrice: 15000000,
    minPerPrice: 1000000,
    repaired: "null",
    empty: false,
    attached: false,
  },
});

export const AllHouseCopy = atom({
  key: "AllHouseCopy",
  default: [],
});

export const HouseReload = atom({
  key: "HouseReload ",
  default: true,
});
