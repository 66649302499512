import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { AllHouseFilter } from "../../atom";
import DropDown from "../../DropDown/DropDown";
import { useBlockStatistic, useBlockStatistics } from "../../queries/queries";
import { BASE_URL_VISUAL } from "../../services/RequestService";
import styles from "./visual.module.scss";
import queryString from "query-string";

const Visual = () => {
  const filter = useRecoilValue<any>(AllHouseFilter);
  const [dropChildren, setDropChildren] = useState<JSX.Element>(<></>);
  const [positionDown, setPositionDown] = useState({
    top: 0,
    left: 0,
    visible: false,
  });
  // const simple = queryString.parse(window.location.search)?.simple;
  // const { data: dataAllHouse } = useBlockStatistics();
  const { data } = useBlockStatistic(filter?.houseId);
  const blockMouseMove = async (e: any) => {
    if (window.innerHeight <= e?.clientY + 350) {
      setPositionDown({
        left: e?.clientX + 20,
        top: window.innerHeight - 350,
        visible: true,
      });
    } else {
      setPositionDown({
        left: e?.clientX + 20,
        top: e?.clientY + 20,
        visible: true,
      });
    }
  };

  const blockMouseOut = (e: any) => {
    setDropChildren(<></>);
    if (window.innerHeight <= e?.clientY + 350) {
      setPositionDown({
        left: e?.clientX + 20,
        top: window.innerHeight - 350,
        visible: false,
      });
    } else {
      setPositionDown({
        left: e?.clientX + 20,
        top: e?.clientY + 20,
        visible: false,
      });
    }
  };

  return (
    <div className={styles.visual}>
      <div className={styles.visual_center}>
        <img src={BASE_URL_VISUAL + data?.houseImagePath} alt="" />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid slice"
          viewBox="0 0 1920 1080"
        >
          {data?.visualViewBlockStatisticWithImages.map((item) => (
            <>
              <Link
                key={item.blockId}
                to={`/visual/${item.blockId + window.location.search}`}
                onMouseEnter={() => {
                  setDropChildren(<></>);
                  let children = (
                    <div>
                      <h1>{item.blockName}-blok</h1>
                      <div>
                        <h1>{item.totalFlatCount} xonadon</h1>
                        <div>
                          {item.roomsCount.map((room) => (
                            <p key={room.roomsCount}>
                              {room.rooms} xonali <b>{room.roomsCount} dona</b>
                            </p>
                          ))}
                        </div>
                      </div>
                    </div>
                  );
                  setTimeout(() => {
                    setDropChildren(children);
                  }, 0);
                }}
                onMouseMove={(e) => {
                  blockMouseMove(e);
                }}
                onMouseOut={blockMouseOut}
              >
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  d={item.buildingSVGImage[0]?.backgroundImage}
                />
                <polygon
                  xmlns="http://www.w3.org/2000/svg"
                  points={item.buildingSVGImage[0]?.frontImage}
                />
              </Link>
            </>
          ))}
        </svg>
      </div>
      <DropDown
        left={positionDown.left}
        top={positionDown.top}
        visible={positionDown.visible}
      >
        {dropChildren}
      </DropDown>
    </div>
  );
};

export default Visual;
