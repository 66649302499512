import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import ArrowDownWhiteIcon from "../../../assets/icons/ArrowDownWhiteIconShowroom";
import DropDown from "../../../DropDown/DropDown";
import { useBlockFloorStatistic } from "../../../queries/queries";
import { BASE_URL_VISUAL } from "../../../services/RequestService";
import styles from "../visual.module.scss";

const Block = () => {
  const [dropChildren, setDropChildren] = useState<JSX.Element>(<></>);
  const [positionDown, setPositionDown] = useState({
    top: 0,
    left: 0,
    visible: false,
  });
  const params = useParams();
  const { data } = useBlockFloorStatistic(Number(params.block));

  const blockMouseMove = async (e: any) => {
    if (window.innerHeight <= e?.clientY + 350) {
      setPositionDown({
        left: e?.clientX + 20,
        top: window.innerHeight - 350,
        visible: true,
      });
    } else {
      setPositionDown({
        left: e?.clientX + 20,
        top: e?.clientY + 20,
        visible: true,
      });
    }
  };

  const blockMouseOut = (e: any) => {
    setDropChildren(<></>);
    if (window.innerHeight <= e?.clientY + 350) {
      setPositionDown({
        left: e?.clientX + 20,
        top: window.innerHeight - 350,
        visible: false,
      });
    } else {
      setPositionDown({
        left: e?.clientX + 20,
        top: e?.clientY + 20,
        visible: false,
      });
    }
  };

  return (
    <div className={styles.visual}>
      <span
        className={styles.visual_back}
        onClick={() => window.history.back()}
      >
        <ArrowDownWhiteIcon /> Ortga
      </span>
      <div className={styles.visual_center}>
        <img src={BASE_URL_VISUAL + data?.imagePath} alt="" />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid slice"
          viewBox="0 0 1920 1080"
        >
          {data &&
            data?.fullVisualViewFloorStatisticList.map((item) => {
              return (
                <Link
                  key={item?.floorName}
                  to={`/visual/${
                    item?.blockId +
                    "/" +
                    item?.floorName +
                    window.location.search
                  }`}
                  onMouseEnter={() => {
                    setDropChildren(<></>);
                    let children = (
                      <div>
                        <h1>
                          {item.floorName}-qavat {item.blockName}-blok
                        </h1>
                        <div>
                          <h1>{item.totalFlatCount} xonadon</h1>
                          <div>
                            {item.roomsCount.map((room) => (
                              <p key={room.roomsCount}>
                                {room.rooms} xonali{" "}
                                <b>{room.roomsCount} dona</b>
                              </p>
                            ))}
                          </div>
                        </div>
                      </div>
                    );
                    setTimeout(() => {
                      setDropChildren(children);
                    }, 0);
                  }}
                  onMouseMove={(e) => {
                    blockMouseMove(e);
                  }}
                  onMouseOut={blockMouseOut}
                >
                  <polygon points={item?.floorPoint && item?.floorPoint[0]} />
                </Link>
              );
            })}
        </svg>
      </div>
      <DropDown
        left={positionDown.left}
        top={positionDown.top}
        visible={positionDown.visible}
      >
        {dropChildren}
      </DropDown>
    </div>
  );
};

export default Block;
