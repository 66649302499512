import React from "react";

const ReceiveSquereIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 7.75C14 11.2 11.2 14 7.75 14C4.3 14 2.19375 10.525 2.19375 10.525M2.19375 10.525H5.01875M2.19375 10.525V13.65M1.5 7.75C1.5 4.3 4.275 1.5 7.75 1.5C11.9188 1.5 14 4.975 14 4.975M14 4.975V1.85M14 4.975H11.225"
        stroke="#FF3F40"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReceiveSquereIcon;
