import React, { FC } from "react";

interface DropDownProps {
  top?: number;
  left?: number;
  visible?: boolean;
  children?: React.ReactNode;
}

const DropDown: FC<DropDownProps> = ({ left, top, visible, children }) => {
  return (
    <div
      className={visible ? "dropDown active" : "dropDown"}
      style={{ top: top, left: left }}
    >
      {children}
    </div>
  );
};

export default DropDown;
