import React, { FC } from "react";
import Apartament from "./Apartament";
import styles from "./bodyContainer.module.scss";

interface IProps {
  setOpenDrawer: React.Dispatch<
    React.SetStateAction<{
      id: number;
      visible: boolean;
    }>
  >;
  data: any[];
  flatId: number;
}

const Floor: FC<IProps> = ({ setOpenDrawer, data, flatId }) => {
  return (
    <div className={styles.bodyContainer_body_entrance_floor}>
      {data.length > 0 &&
        data.map((item, index) => (
          <Apartament
            key={index}
            number={item?.number}
            id={item?.id}
            status={item?.status}
            disable={item?.disable}
            news={item?.new}
            flatId={flatId}
            setOpenDrawer={setOpenDrawer}
          />
        ))}
    </div>
  );
};

export default Floor;
