import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import ArrowDownBlackIcon from "../../../assets/icons/ArrowDownBlackIconShowroom";
import { useBlockFlatStatistic } from "../../../queries/queries";
import { BASE_URL_VISUAL } from "../../../services/RequestService";
import { defaultImage } from "../../../utils/constants/defaultImage";
import styles from "../visual.module.scss";

interface IProps {
  setOpenDrawer: React.Dispatch<
    React.SetStateAction<{
      id: number;
      visible: boolean;
    }>
  >;
  flatId: number;
}

const Floor: FC<IProps> = ({ setOpenDrawer, flatId }) => {
  const [imageSize, setImageSize] = useState({ width: 566, height: 566 });
  const params = useParams();

  const { data } = useBlockFlatStatistic(
    Number(params.block),
    Number(params.floor)
  );

  const loadImage = (e: any) => {
    setImageSize({
      width: e.target.naturalWidth,
      height: e.target.naturalHeight,
    });
  };
  return (
    <div className={styles.visual}>
      <span
        className={styles.visual_back}
        onClick={() => window.history.back()}
      >
        <ArrowDownBlackIcon /> <span style={{ color: "#000000" }}>Ortga</span>
      </span>
      <div className={styles.visual_floor}>
        <img
          src={
            data?.buildingFloorImagePath
              ? BASE_URL_VISUAL + data?.buildingFloorImagePath
              : defaultImage
          }
          alt=""
          onLoad={(e) => {
            loadImage(e);
          }}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          viewBox={`0 0 ${imageSize.width} ${imageSize.height}`}
          xmlSpace="preserve"
        >
          {data?.buildingFloorImagePath &&
            data?.blockFlatDataDtoList.map((item) => {
              let xArr =
                item?.flatImage &&
                item?.flatImage
                  .split(" ")
                  .map((item: any) => item.split(","))
                  .map((arr: any) => Number(arr[0]))
                  .filter((i: any) => !isNaN(i) && i !== 0);
              let yArr =
                item?.flatImage &&
                item?.flatImage
                  .split(" ")
                  .map((item: any) => item.split(","))
                  .map((arr: any) => Number(arr[1]))
                  .filter((i: any) => !isNaN(i) && i !== 0);

              let x =
                item?.flatImage &&
                Math.max(...xArr) - (Math.max(...xArr) - Math.min(...xArr)) / 2;
              let y =
                item?.flatImage &&
                Math.max(...yArr) - (Math.max(...yArr) - Math.min(...yArr)) / 2;

              // console.log(xArr, x);
              return (
                <Link
                  to={window.location.pathname + window.location.search}
                  key={item.flatId}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenDrawer({
                      visible: true,
                      id: item.flatId,
                    });
                  }}
                  style={{
                    pointerEvents:
                      item.flatStatus === "SALE" ? "inherit" : "none",
                    zIndex: item.flatStatus === "SALE" ? "0" : "1",
                  }}
                >
                  <polygon
                    className="st0"
                    points={item.flatImage}
                    fill={
                      item.flatStatus === "SOLD"
                        ? "rgba(30, 203, 137, 0.9)"
                        : item.flatStatus === "BOOKED" ||
                          item.flatStatus === "RESERVE"
                        ? "rgba(249, 218, 141, 0.9)"
                        : "rgba(91, 93, 101, 0.9)"
                    }
                    style={{
                      fill: item.flatId === flatId ? "#f9da8d50" : "",
                    }}
                  />
                  {item.flatStatus === "SOLD" ? (
                    <>
                      <rect
                        x={x - 47}
                        y={y}
                        width="60"
                        height="25"
                        rx="12.5"
                        fill="#ffffff"
                      />
                      <text x={x - 38} y={y + 17} style={{ fontSize: 12 }}>
                        Sotilgan
                      </text>
                    </>
                  ) : item.flatStatus === "BOOKED" ||
                    item.flatStatus === "RESERVE" ? (
                    <>
                      <rect
                        x={x - 47}
                        y={y}
                        width="60"
                        height="25"
                        rx="12.5"
                        fill="#ffffff"
                      />
                      <text x={x - 38} y={y + 17} style={{ fontSize: 12 }}>
                        Booked
                      </text>
                    </>
                  ) : (
                    <>
                      <rect
                        x={x - 20}
                        y={y - 20}
                        width="40"
                        height="40"
                        rx="50"
                        fill="#A4A4A4"
                      />
                      <text x={x - 8} y={y + 4}>
                        {item.flatRooms}X
                      </text>
                    </>
                  )}
                </Link>
              );
            })}
        </svg>
      </div>
    </div>
  );
};

export default Floor;
